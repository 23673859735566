import { Box, Button, CardMedia, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { Applicant } from '../../redux-store/types/api-types';
import { riskProfileDeclaration } from '../../utils/declaration';
import { SubHeading } from '../investors/components';
import { Gridstyles, Item, ItemDetails } from './index';
import { APMIFlow } from '../../utils/constant';

export const getApplicant = (applicants: Partial<Applicant>[]) => {
  return applicants?.find((applicant) => Number(applicant.applicant_type) === 1);
};

export default function TypeDeclaration({
  applicants = [],
  declarationForRiskProfile,
}: {
  applicants: Partial<Applicant>[];
  declarationForRiskProfile: boolean;
}): JSX.Element {
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [readmoreDisclaimer, setReadmoreDisclaimer] = useState(false);
  const updatedDocuments = applicants
    .find((applicant) => applicant.applicant_type === '1')
    ?.documents?.filter((doc) => doc.documentType === 'typedDeclaration');
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Investor(s) Declaration(s)
            </Typography>
            {applicants?.map((applicant) => applicant.typedDeclaration)?.some((ele) => ele) &&
              (referenceDetail.applicant_type
                ? applicants.filter(
                    (applicant) =>
                      Number(applicant.applicant_type) === Number(referenceDetail.applicant_type)
                  )
                : applicants
              ).map((applicant, index) => {
                return !APMIFlow ? (
                  applicant.typedDeclaration &&
                    (referenceDetail.applicant_type ? referenceDetail.applicant_type === '1' : true)
                ) : applicant.typedDeclaration ? (
                  <>
                    <SubHeading
                      sx={{
                        letterSpacing: 0.5 + 'px',
                        padding: { xs: '10px 10px', sm: '10px 30px' },
                      }}>
                      {
                        ['First/Solo Applicant', 'Second Applicant', 'Third Applicant'][
                          referenceDetail.applicant_type
                            ? Number(referenceDetail.applicant_type) - 1
                            : index
                        ]
                      }{' '}
                      Fee Declaration
                    </SubHeading>
                    <React.Fragment>
                      <Box sx={{ width: '100%' }}>
                        <Gridstyles>
                          <Grid
                            item
                            container
                            pb={5}
                            rowSpacing={6}
                            columnSpacing={13}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start">
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              {/* <Checkbox defaultChecked disabled={true} /> */}
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 700,
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  letterSpacing: '0.5px',
                                }}>
                                {getApplicant(applicants)?.typedDeclaration || '-'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Gridstyles>
                      </Box>
                    </React.Fragment>
                  </>
                ) : (
                  <></>
                );
              })}

            {getApplicant(applicants)?.isFileUploadAtTypedDeclaration &&
              (referenceDetail.applicant_type ? referenceDetail.applicant_type === '1' : true) && (
                <>
                  <SubHeading
                    sx={{
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    Fee Declaration
                  </SubHeading>
                  <React.Fragment>
                    <Grid
                      container
                      pt={updatedDocuments && updatedDocuments.length && 2}
                      pb={updatedDocuments && updatedDocuments.length && 5}
                      columnSpacing={13}
                      sx={{ pl: { xs: 0, sm: 7, md: 10, lg: 13 } }}>
                      {updatedDocuments &&
                        updatedDocuments.map((document, index: number) => {
                          return (
                            <React.Fragment key={document.id}>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={document.file ? 6 : 12}
                                sx={{ mt: index !== 0 ? 6 : 0 }}>
                                <Item>{document.documentName}</Item>
                                <ItemDetails>{document.file?.name}</ItemDetails>
                              </Grid>
                              {document.file && (
                                <Grid item xs={12} sm={6}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                      flexDirection: { sm: 'column', xs: 'row', md: 'row' },
                                    }}>
                                    <Button
                                      variant="contained"
                                      sx={{
                                        color: 'common.white',
                                        // minWidth: { xs: '90%', sm: '100%', md: '70%' },
                                        minWidth: '70%',
                                        // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                        fontWeight: 600,
                                        lineHeight: 1.5,
                                      }}
                                      onClick={() => window.open(document.file?.viewUrl)}>
                                      View
                                    </Button>
                                    <Tooltip title={'Download'}>
                                      <IconButton
                                        sx={{
                                          // p: 0,
                                          // pl: 1.5,
                                          ':hover': {
                                            bgcolor: '#F4FCFC',
                                          },
                                          cursor: 'pointer',
                                          pointerEvents: 'all',
                                          // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                        }}
                                        onClick={() => window.open(document.file?.url)}>
                                        <CardMedia
                                          component="img"
                                          src={'/images/download-icon.png'}
                                          alt="Download"
                                          sx={{ width: '25px' }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                              )}
                            </React.Fragment>
                          );
                        })}
                    </Grid>
                  </React.Fragment>
                </>
              )}
            {applicants
              ?.map((applicant) => applicant.fatcaDeclaration)
              ?.some((ele) => ele === true) &&
              (referenceDetail.applicant_type
                ? applicants.filter(
                    (applicant) =>
                      Number(applicant.applicant_type) === Number(referenceDetail.applicant_type)
                  )
                : applicants
              ).map((applicant, index) => {
                return applicant.fatcaDeclaration ? (
                  <>
                    <SubHeading
                      sx={{
                        letterSpacing: 0.5 + 'px',
                        padding: { xs: '10px 10px', sm: '10px 30px' },
                      }}>
                      {
                        ['First/Solo Applicant', 'Second Applicant', 'Third Applicant'][
                          referenceDetail.applicant_type
                            ? Number(referenceDetail.applicant_type) - 1
                            : index
                        ]
                      }{' '}
                      FATCA Declaration
                    </SubHeading>
                    <React.Fragment>
                      <Box sx={{ width: '100%' }}>
                        <Gridstyles>
                          <Grid
                            item
                            container
                            pb={5}
                            rowSpacing={6}
                            columnSpacing={13}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start">
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              {/* <Checkbox defaultChecked disabled={true} /> */}
                              <Typography>
                                <Typography>
                                  I declare / I am authorized to declare that the above information
                                  and information in the submitted documents, to be true, correct,
                                  and updated, and the submitted documents are genuine and duly
                                  executed.
                                  {!readmoreDisclaimer && (
                                    <>
                                      ..
                                      <Link
                                        sx={{
                                          ml: 2,
                                          textDecoration: 'none',
                                          fontWeight: 600,
                                          cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setReadmoreDisclaimer(true);
                                        }}>
                                        Read More
                                      </Link>
                                    </>
                                  )}
                                </Typography>
                                {readmoreDisclaimer && (
                                  <>
                                    <Typography sx={{ mt: 3 }}>
                                      I acknowledge / I am authorized to acknowledge that towards
                                      compliance with tax information-sharing laws, such as FATCA /
                                      CRS, the SEBI authorized intermediary may be required to seek
                                      additional personal, tax, and beneficial owner information and
                                      certain certifications and documentation from the Account
                                      Applicant. Such information may be sought either at the time
                                      of account opening or any time subsequently. In certain
                                      circumstances (including if the intermediary does not receive
                                      a valid self-certification from the applicant) the
                                      Intermediary may be obliged to share information on
                                      Applicant’s Account with relevant tax authorities. Should
                                      there be any change in any information provided, ensure that
                                      the Applicant will intimate the Institution promptly, i.e.,
                                      within 30 days.
                                    </Typography>
                                    <Typography sx={{ mt: 3 }}>
                                      Towards compliance with such laws, the Intermediary may also
                                      be required to provide information to any institutions such as
                                      withholding agents for the purpose of ensuring appropriate
                                      withholding from the account or any proceeds in relation
                                      thereto. As may be required by domestic or overseas
                                      regulators/ tax authorities, the Intermediary may also be
                                      constrained to withhold payout of any sums from the
                                      Applicant’s Account or close or suspend the Applicant’s
                                      Account.
                                    </Typography>
                                    <Typography sx={{ mt: 3 }}>
                                      I also understand that the account will be reported if any one
                                      of the aforesaid FATCA / CRS criteria for any of the Account
                                      Applicants, i.e., Primary or Joint, is met.
                                      {readmoreDisclaimer && (
                                        <Link
                                          sx={{
                                            ml: 2,
                                            textDecoration: 'none',
                                            fontWeight: 600,
                                            cursor: 'pointer',
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setReadmoreDisclaimer(false);
                                          }}>
                                          Read Less
                                        </Link>
                                      )}
                                    </Typography>
                                  </>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Gridstyles>
                      </Box>
                    </React.Fragment>
                  </>
                ) : (
                  <></>
                );
              })}
            {declarationForRiskProfile &&
              (referenceDetail.applicant_type ? referenceDetail.applicant_type === '1' : true) && (
                <>
                  <SubHeading
                    sx={{
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    Risk Profile Declaration
                  </SubHeading>
                  <React.Fragment>
                    <Box sx={{ width: '100%' }}>
                      <Gridstyles>
                        <Grid
                          item
                          container
                          pb={5}
                          rowSpacing={6}
                          columnSpacing={13}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start">
                          <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                            {/* <Checkbox defaultChecked disabled={true} /> */}
                            <Typography
                              sx={{
                                fontSize: 15,
                                fontWeight: 700,
                                color: 'rgba(0, 0, 0, 0.6)',
                                letterSpacing: '0.5px',
                              }}>
                              {riskProfileDeclaration}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Gridstyles>
                    </Box>
                  </React.Fragment>
                </>
              )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
