import { Box, Typography, Button, AppBar, CardMedia, Divider, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApplicationProps,
  FeeSlabsType,
  nationaliyType,
  RiskProfileMaster,
  StrategyType,
} from '../../redux-store/types/api-types';
import { useHistory } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { Footer } from '../commonComponents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ApplicationDetailsCommonLayout } from '../ApplicationDetails/index';
import { Location } from 'history';
import {
  getApplicationDetails,
  getCompanies,
  getDocuments,
  isFormValidForSubmission,
} from '../../redux-store/actions/application';
import { showError } from '../../redux-store/actions/auth';
import { Distributor, getNationalityList, riskProfileMasterData } from '../../redux-store/actions';
import {
  getFeeSlabListOfDirect,
  getStarategyListOfDirect,
  getStrategies,
} from '../../redux-store/actions/strategies';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import { getDistributorById } from '../../redux-store/actions/userManagement';
import { companyType } from '../../redux-store/types/mdms';
import { useValidationOrConfigHook } from '../../utils/validationOrConfigHook';

export default function InvestorApplicationBeforeFaceCapture({
  location,
}: {
  location: Location<{
    applicationId: string;
    applicantId: string;
  }>;
}): JSX.Element {
  const { applicationId = '', applicantId = '' } = location.state || {};
  const { referenceId } = useParams<{ referenceId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { investor, auth } = useSelector((store: RootStateType) => store);
  const [application, setApplication] = useState<ApplicationProps>();
  const [loading, setLoading] = useState(false);
  // const [fatcaDetails, setFatcaDetails] = useState(initialValues);
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const validationOrConfigChecks = useValidationOrConfigHook();

  useEffect(() => {
    (async function () {
      try {
        const _application = (await dispatch(
          getApplicationDetails(applicationId)
        )) as unknown as ApplicationProps;
        setApplication(_application);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  useEffect(() => {
    const { token } = investor;
    if (!token || !referenceId) {
      history.push(`/investor-liveliness/${referenceId}/login`);
    }
  }, []);

  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <IconButton
        sx={{
          position: 'fixed',
          right: 0,
          bottom: 75,
          borderRadius: '5px  0 0 5px',
          '&,:hover': {
            bgcolor: 'primary.main',
          },
        }}
        onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
        <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
      </IconButton>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box>
          <CardMedia
            component="img"
            src="/images/motilal-logo.png"
            alt="Logo"
            sx={{ width: '120px', m: 3 }}
          />
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box sx={{ py: 5, px: { xs: 5, sm: 10 } }} ref={applicationDetailRef}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            mb: 2,
            justifyContent: 'space-between',
            mt: 10,
          }}>
          <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main', mb: 4 }}>
            Application Details
          </Typography>
          {application?.editable && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() =>
                  history.push(
                    `/investor-liveliness/edit-application/${referenceId}/distributor-details`,
                    {
                      id: application?.id,
                      applicant1ReferenceId: referenceId,
                    }
                  )
                }
              />
            </IconButton>
          )}
        </Box>
        {application && (
          <>
            <ApplicationDetailsCommonLayout
              loading={loading}
              application={application as ApplicationProps}
            />

            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                sx={{
                  color: 'common.white',
                  minWidth: '200px',
                  mt: 4,
                  fontWeight: 600,
                  lineHeight: 1.5,
                }}
                onClick={async () => {
                  try {
                    setIsSubmitting(true);
                    const nationalitiesMdmsMasters = (await dispatch(
                      getNationalityList()
                    )) as unknown as nationaliyType;
                    const responseRiskProfile = (await dispatch(
                      riskProfileMasterData()
                    )) as unknown as RiskProfileMaster;
                    const response = (await dispatch(getDocuments())) as unknown as Document;
                    let responseStrategies = [] as StrategyType[];
                    let responseFeeSlabs = [] as FeeSlabsType[];
                    if (application?.distributor_id) {
                      const distributor = (await dispatch(
                        getDistributorById(application?.distributor_id)
                      )) as unknown as Distributor;
                      responseStrategies = distributor.strategies as StrategyType[];
                      responseFeeSlabs = distributor.feeSlabs as FeeSlabsType[];
                    } else {
                      responseStrategies = (await dispatch(
                        getStarategyListOfDirect()
                      )) as unknown as StrategyType[];
                      responseFeeSlabs = (await dispatch(
                        getFeeSlabListOfDirect()
                      )) as unknown as FeeSlabsType[];
                    }
                    const companyList = (await dispatch(getCompanies())) as unknown as companyType;
                    await isFormValidForSubmission(
                      application,
                      true,
                      true,
                      nationalitiesMdmsMasters,
                      validationOrConfigChecks,
                      response,
                      auth.role,
                      responseRiskProfile.riskProfile,
                      responseStrategies,
                      responseFeeSlabs,
                      referenceDetail,
                      companyList.companies
                    );
                    setIsSubmitting(false);
                    history.push(`location-capture`, { applicantId });
                  } catch (e) {
                    setIsSubmitting(false);
                    typeof e === 'string' && dispatch(showError(e));
                    console.error((e as Error).message);
                  }
                }}>
                {`Proceed ${isSubmitting ? '...' : ''}`}
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Footer />
    </Box>
  );
}
