import {
  Box,
  Button,
  CardMedia,
  Dialog,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APMIFlow,
  APPLICATION_TYPE,
  CHECKER_EDIT_ALL_SECTIONS,
  USER_ROLES,
} from '../../utils/constant';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useFormikContext } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps } from '@mui/system';
import { getApplicantIdForInvestorPhotoCapture } from '../../utils/utilityFunctions';

export const investorEditRiskProfileObj = {
  tabText: 'Risk Profile',
  header: 'Risk Profile',
  endPoint: 'risk-profiles',
};
export const investorEditDisclosureOfInterestObj = {
  tabText: 'Disclosure Of Interest',
  header: 'Disclosure Of Interest',
  endPoint: 'disclosure-of-interest',
};
export const investorEditDisclosureOfExclusionObj = {
  tabText: 'Disclosure Of Exclusions',
  header: 'Disclosure Of Exclusions',
  endPoint: 'disclosure-of-exclusions',
};
export const investorEditDocumentDetailsObj = {
  tabText: 'Document Details',
  header: 'Document Details',
  endPoint: 'document-details',
};
export const investorEditTabtextExcludingDoc = [
  investorEditRiskProfileObj.tabText,
  investorEditDisclosureOfInterestObj.tabText,
  investorEditDisclosureOfExclusionObj.tabText,
];

export const investorEditTabtextIncludingDoc = [
  ...investorEditTabtextExcludingDoc,
  investorEditDocumentDetailsObj.tabText,
];

export const investorEditHeaderExcludingDoc = [
  investorEditRiskProfileObj.header,
  investorEditDisclosureOfInterestObj.header,
  investorEditDisclosureOfExclusionObj.header,
];

export const investorEditHeadertIncludingDoc = [
  ...investorEditHeaderExcludingDoc,
  investorEditDocumentDetailsObj.header,
];

export const investorEditEndpointExcludingDoc = [
  investorEditRiskProfileObj.endPoint,
  investorEditDisclosureOfInterestObj.endPoint,
  investorEditDisclosureOfExclusionObj.endPoint,
];

export const investorEditEndpointIncludingDoc = [
  ...investorEditEndpointExcludingDoc,
  investorEditDocumentDetailsObj.endPoint,
];

export const InvestorDetailsLayout = ({
  applicationId,
  applicationNumber = '',
  applicant1ReferenceId = '',
  investorLoginType = '',
  children,
}: {
  applicationId: string;
  applicationNumber: string;
  applicant1ReferenceId?: string;
  investorLoginType?: string;
  children: JSX.Element;
}): JSX.Element => {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { currentStep } = application || {};
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { path } = useRouteMatch();
  const history = useHistory();
  const [transitionClassName, setTransitionClassName] = useState('');
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const result = useRouteMatch<{ slug: string }>({
    path: `${path}/:slug/`,
  });
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { investorEditObj } = useSelector((store: RootStateType) => store.investorEditObj);
  const checkLoginForInvestor =
    referenceDetail.applicant_type && investorEditObj && investorEditObj.length > 0;
  // Investor Details Layout
  const MenuItemList =
    USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
      ? ['Strategy Details']
      : checkLoginForInvestor || (APMIFlow && referenceDetail.applicant_type === '1')
      ? APMIFlow && referenceDetail.applicant_type === '1'
        ? investorEditObj?.find((menu) => menu.tabText === investorEditDocumentDetailsObj.tabText)
          ? investorEditTabtextIncludingDoc
          : investorEditTabtextExcludingDoc
        : (investorEditObj?.map((menu) => menu.tabText) as string[])
      : [
          'RM Details',
          'Applicant(s) Details',
          'Contact Details',
          'Additional KYC Details',
          'FATCA',
          'Nominee Details',
          'Bank Details',
          // 'Investor Questionnaires',
          'Risk Profile',
          'Strategy Details',
          'Disclosure Of Interest',
          'Disclosure Of Exclusions',
          'Related Party Consent',
          // 'Typed Declaration',
          'Document Details',
        ];
  const HeaderList =
    USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
      ? ['Strategy Details']
      : checkLoginForInvestor || (APMIFlow && referenceDetail.applicant_type === '1')
      ? APMIFlow && referenceDetail.applicant_type === '1'
        ? investorEditObj?.find((menu) => menu.header === investorEditDocumentDetailsObj.header)
          ? investorEditHeadertIncludingDoc
          : investorEditHeaderExcludingDoc
        : (investorEditObj?.map((menu) => menu.header) as string[])
      : [
          'RM Details',
          'Applicant(s) Details',
          'Contact Details',
          'Additional KYC Details',
          'FATCA',
          'Nominee Details',
          'Bank Details',
          // 'Investor Questionnaires',
          'Risk Profile',
          'Strategy Details',
          'Disclosure Of Interest',
          'Disclosure Of Exclusions',
          'Related Party Consent',
          // 'Typed Declaration',
          'Document Details',
        ];
  const MenuItemListLink =
    USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
      ? ['strategy-details']
      : checkLoginForInvestor || (APMIFlow && referenceDetail.applicant_type === '1')
      ? APMIFlow && referenceDetail.applicant_type === '1'
        ? investorEditObj?.find((menu) => menu.endPoint === investorEditDocumentDetailsObj.endPoint)
          ? investorEditEndpointIncludingDoc
          : investorEditEndpointExcludingDoc
        : (investorEditObj?.map((menu) => menu.endPoint) as string[])
      : [
          'distributor-details',
          'contributor-details',
          'contact-details',
          'additional-KYC-details',
          'fatca',
          'nominee-details',
          'bank-details',
          // 'investor-questionnaires',
          'risk-profiles',
          'strategy-details',
          'disclosure-of-interest',
          'disclosure-of-exclusions',
          'related-party-consent',
          // 'Typed-declaration',
          'document-details',
        ];

  const NonIndividualMenuItemList = [
    'Distributor details',
    'Applicant(s) Details',
    'Contact Details',
    // 'Investment & Payment Details',
    "Investor's Bank Details",
    'Document Details',
    'Authorized Signatory',
    'Declaration of UBO',
    'Risk Profiles',
    'Strategy Details',
    'Disclosure Of Interest',
    'Disclosure Of Exclusions',
    'Related Party Consent',
    'FATCA',
    // 'Risk Profile',
  ];

  const NonIndividualHeaderList = [
    'Distributor details',
    'Applicant(s) Details',
    'Contact Details',
    // 'Investment Details',
    "Investor's Bank Details",
    'Document Details',
    'Authorized Signatory',
    'Declaration of Ultimate Beneficial Ownership (UBO)',
    'Risk Profiles',
    'Strategy Details',
    'Disclosure Of Interest',
    'Disclosure Of Exclusions',
    'Related Party Consent',
    'FATCA',
    // 'Risk Profile',
  ];

  const NonIndividualMenuItemListLink = [
    'distributor-details',
    'contributor-details',
    'contact-details',
    // 'investment-payment-details',
    'bank-details',
    'document-details',
    'authorised-signatory',
    'declaration-ubo',
    'risk-profiles',
    'strategy-details',
    'disclosure-of-interest',
    'disclosure-of-exclusions',
    'related-party-consent',
    'fatca',
    // 'risk-profile',
  ];

  const activeItem = result
    ? application?.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
      ? NonIndividualMenuItemListLink.indexOf(result.params.slug)
      : MenuItemListLink.indexOf(
          (investorLoginType === 'investor_photoCapture'
            ? history.location.pathname.split('/').pop()
            : result.params.slug) || ''
        )
    : 0;
  const menuList =
    application &&
    (application.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
      ? NonIndividualMenuItemList
      : MenuItemList);
  useEffect(() => {
    window.scrollTo(0, 0);
    setTransitionClassName('slideInUp');
    const timeoutId = setTimeout(() => {
      setTransitionClassName('');
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeItem]);

  return (
    <>
      {(!checkLoginForInvestor || APMIFlow) && (
        <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', mb: 2 }}>
          <IconButton
            onClick={() => {
              investorLoginType === 'investor_photoCapture'
                ? history.push(
                    `/investor-liveliness/${applicant1ReferenceId}/application-details`,
                    {
                      applicationId,
                      applicantId: getApplicantIdForInvestorPhotoCapture(
                        application,
                        applicant1ReferenceId
                      ),
                    }
                  )
                : history.push(
                    role === USER_ROLES.INVESTOR
                      ? `/investment-details/${applicant1ReferenceId}/application-details`
                      : [USER_ROLES.AMCAPPROVER, USER_ROLES.POAAPPROVER].includes(role)
                      ? `/application-details/${applicationId}`
                      : '/applications'
                  );
            }}>
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
          <Typography sx={{ fontWeight: 500, fontFamily: 'Poppins, sans-serif' }}>Back</Typography>
        </Box>
      )}
      {/* Mobile dropdown */}
      <Box sx={{ display: { xs: 'block', md: 'none' }, mx: -2 }}>
        <Button
          variant="text"
          fullWidth
          id="application-button"
          aria-controls="application-dropdown"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={<CardMedia component="img" src="/images/person.svg" alt="Icon" />}
          endIcon={<ExpandMoreIcon />}
          sx={{
            color: 'text.primary',
            bgcolor: '#F7FAFD',
            px: 2,
            justifyContent: 'flex-start',
            textAlign: 'left',
            borderBottom: '1px solid #E8E8E8',
            borderRadius: 0,
            '.MuiButton-endIcon': {
              ml: 'auto',
            },
          }}>
          <Box>
            <Typography variant="body2">
              Page {activeItem + 1}/{' '}
              {application &&
                (application.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                  ? NonIndividualHeaderList.length
                  : HeaderList.length)}
            </Typography>
            <Typography sx={{ fontWeight: 500 }}>
              {application &&
                (application.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                  ? NonIndividualHeaderList[activeItem]
                  : HeaderList[activeItem])}
            </Typography>
          </Box>
        </Button>
        <Menu
          id="application-dropdown"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'application-button',
          }}
          sx={{
            '.MuiPaper-root ': {
              width: '100%',
              bgcolor: '#F7FAFD',
              '.MuiList-root': {
                p: 0,
                '.MuiDivider-root': { m: 0 },
                '.MuiMenuItem-root': {
                  '.MuiListItemText-primary': {
                    fontWeight: 500,
                    fontSize: 14,
                  },
                  [`:nth-of-type(${activeItem + 1})`]: {
                    '.MuiListItemText-primary': {
                      fontWeight: 600,
                      color: 'primary.main',
                    },
                  },
                },
              },
            },
          }}>
          {menuList &&
            menuList.map((item, index) => {
              const isItemDisabled = !currentStep ? index !== 0 : index > currentStep;
              return (
                <>
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setAnchorEl(null);
                      history.push(
                        `${
                          application &&
                          (application.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                            ? NonIndividualMenuItemListLink[index]
                            : MenuItemListLink[index])
                        }`,
                        {
                          id: applicationId,
                          applicant1ReferenceId,
                        }
                      );
                    }}
                    sx={{ minHeight: '30px' }}
                    disabled={isItemDisabled || (checkLoginForInvestor && !APMIFlow) || false}>
                    <ListItemText
                      sx={{
                        color: isItemDisabled || currentStep === index ? '#575757' : '#57B6BA',
                        opacity: isItemDisabled ? 0.5 : 1,
                      }}>
                      {item}
                    </ListItemText>
                    <ArrowForwardIcon sx={{ color: '#00D9DA' }} />
                  </MenuItem>
                  <Divider />
                </>
              );
            })}
        </Menu>
      </Box>
      {/* Mobile dropdown End */}
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center',
          justifyContent: 'space-between',
          p: '10px 20px',
          mb: 2,
          bgcolor: '#e5f1f8',
          borderRadius: '10px',
          zIndex: 10,
          position: 'sticky',
          top: '63px',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {(!checkLoginForInvestor || APMIFlow) && (
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() => {
                investorLoginType === 'investor_photoCapture'
                  ? history.push(
                      `/investor-liveliness/${applicant1ReferenceId}/application-details`,
                      {
                        applicationId,
                        applicantId: getApplicantIdForInvestorPhotoCapture(
                          application,
                          applicant1ReferenceId
                        ),
                      }
                    )
                  : history.push(
                      role === USER_ROLES.INVESTOR
                        ? `/investment-details/${applicant1ReferenceId}/application-details`
                        : [USER_ROLES.AMCAPPROVER, USER_ROLES.POAAPPROVER].includes(role)
                        ? `/application-details/${applicationId}`
                        : '/applications'
                    );
              }}>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
          )}
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              color: '#1C2D47',
            }}>
            {application &&
              (application.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                ? [USER_ROLES.AMCAPPROVER].includes(role)
                  ? 'Edit Application'
                  : 'Non Individual Investor Onboarding'
                : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER, USER_ROLES.AMCAPPROVER].includes(
                    role
                  )
                ? 'Edit Application'
                : 'Investor Onboarding')}
          </Typography>
        </Box>
        <Typography sx={{ fontWeight: 600, letterSpacing: '1px' }}></Typography>
        {!!applicationNumber && (
          <Typography
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
              fontSize: '16px',
              color: '#1C2D47',
              opacity: 0.7,
            }}>
            Application ID: {applicationNumber}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          // py:1,
          bgcolor: 'white',
          boxShadow: { xs: '', md: '0px 4px 12px rgba(0, 0, 0, 0.05)' },
          borderRadius: '10px',
          // height: { xs: 'calc(100vh - 100px)', md: 'calc(100vh - 165px)' },
          // overflowY: 'auto',
        }}>
        <Box>
          <Grid
            container
            sx={
              {
                // height: { xs: '', md: 'calc(100vh - 200px)' },
                // overflowY: 'scroll',
                // '::-webkit-scrollbar': {
                //   display: 'none',
                // },
              }
            }>
            <Grid
              item
              xs={2}
              sx={{
                display: { xs: 'none', md: 'block' },
                backgroundColor: 'rgba(51, 127, 201, 0.025)',
              }}>
              <Stack sx={{ position: 'sticky', top: '108px', py: 4 }}>
                <MenuList>
                  {menuList &&
                    menuList.map((item, index) => {
                      const isItemDisabled = !currentStep ? index !== 0 : index > currentStep;
                      return (
                        <MenuItem
                          key={index}
                          // component={RouterLink}
                          // to={`${MenuItemListLink[index]}`}
                          onClick={() =>
                            history.push(
                              `${
                                application &&
                                (application.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                                  ? NonIndividualMenuItemListLink[index]
                                  : MenuItemListLink[index])
                              }`,
                              {
                                id: applicationId,
                                applicant1ReferenceId,
                              }
                            )
                          }
                          disabled={isItemDisabled || (checkLoginForInvestor && !APMIFlow) || false}
                          sx={{
                            color: isItemDisabled || currentStep === index ? '#575757' : '#57B6BA',
                            opacity: isItemDisabled ? 0.5 : 1,
                            fontSize: 14,
                            fontWeight: 500,
                            my: 1,
                            whiteSpace: 'break-spaces',
                            justifyContent: 'flex-end',
                            textAlign: 'right',
                            ':hover': {
                              background:
                                'linear-gradient(88.93deg, rgba(51, 127, 201, 0.0875) 10.58%, rgba(51, 127, 201, 0.025) 93.37%)',
                            },
                            [`:nth-of-type(${activeItem + 1})`]: {
                              fontWeight: 600,
                              color: 'primary.main',
                              background:
                                'linear-gradient(88.93deg, rgba(51, 127, 201, 0.0875) 10.58%, rgba(51, 127, 201, 0.025) 93.37%)',
                              '&:after': {
                                content: '""',
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                width: '4px',
                                height: '100%',
                                bgcolor: 'primary.main',
                                borderRadius: '5px',
                              },
                            },
                          }}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </MenuList>
              </Stack>
            </Grid>
            <Grid item container xs={12} md={10} sx={{ py: 5 }}>
              <Grid item xs={12} md={1} sx={{ display: { xs: 'none', md: 'block' } }} />
              <Grid item xs={12} md={10}>
                <Box sx={{ px: { xs: 1, md: 'unset' } }} className={transitionClassName}>
                  <Typography
                    component="h3"
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 25,
                      fontWeight: 500,
                      mb: { xs: 2, md: 3.5 },
                      color:
                        application &&
                        (application?.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                          ? '#0D2762'
                          : 'secondary.main'),
                    }}>
                    {application &&
                      (application.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                        ? NonIndividualHeaderList[activeItem]
                        : HeaderList[activeItem])}
                  </Typography>
                  {children}
                </Box>
              </Grid>
              <Grid item xs={12} md={1} sx={{ display: { xs: 'none', md: 'block' } }} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
// Investor Details Layout End

export const SubHeading = styled('h3')(({ theme }) => ({
  backgroundColor: 'rgba(221, 234, 243, 0.34)',
  color: theme.palette.primary.main,
  fontSize: '14px',
  fontWeight: 600,
  borderRadius: '5px',
  width: '100%',
  letterSpacing: '1px',
  // marginLeft: '40px',
  marginTop: '30px',
  padding: '10px 30px',
  [theme.breakpoints.down('md')]: {
    padding: '10px 20px',
  },
  // [theme.breakpoints.down('md')]: {
  //   textAlign: 'center',
  // },
}));

export const ProceedSaveLater = ({
  saveLater,
  saveAndProceed,
  loader,
  clickedButton,
  saveButtonText = 'Save and Proceed',
  saveAndProceedType = 'submit',
  showEndIcon = true,
  showSaveForLater = true,
  showSaveAndProceed = true,
  disabled,
  saveForLaterButtonText = 'Save For Later',
}: {
  saveLater?: () => void;
  saveAndProceed?: () => void;
  loader?: boolean;
  clickedButton?: string;
  saveAndProceedType?: 'button' | 'submit' | 'reset' | undefined;
  saveButtonText?: string;
  showEndIcon?: boolean;
  showSaveForLater?: boolean;
  showSaveAndProceed?: boolean;
  disabled?: boolean;
  saveForLaterButtonText?: string;
}): JSX.Element => {
  return (
    <Box
      sx={{
        width: '100%',
        mx: 'auto',
        mt: 5,
        display: 'flex',
        flexWrap: 'wrap',
        gap: 3,
        justifyContent: 'center',
        '.MuiButton-root': {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.5,
        },
      }}>
      {showSaveForLater && (
        <LoadingButton
          loadingPosition="start"
          type="submit"
          onClick={saveLater}
          loading={clickedButton === 'save for later' ? loader : false}
          fullWidth
          sx={{
            color: 'text.primary',
            border: '1px dashed #AAB2BA',
            maxWidth: '165px',
          }}
          disabled={disabled ? disabled : loader}>
          {saveForLaterButtonText}
        </LoadingButton>
      )}
      {showSaveAndProceed ? (
        <LoadingButton
          loadingPosition="start"
          type={saveAndProceedType}
          loading={clickedButton === 'save and proceed' ? loader : false}
          variant="contained"
          fullWidth
          onClick={saveAndProceed}
          endIcon={showEndIcon && <KeyboardArrowRightSharpIcon />}
          sx={{ maxWidth: { xs: '170px', sm: '220px' } }}
          disabled={disabled ? disabled : loader}>
          {saveButtonText}
        </LoadingButton>
      ) : (
        <LoadingButton
          loadingPosition="start"
          type={saveAndProceedType}
          loading={clickedButton === 'save and proceed' ? loader : false}
          variant="contained"
          fullWidth
          endIcon={showEndIcon && <KeyboardArrowRightSharpIcon />}
          sx={{ maxWidth: { xs: '170px', sm: '220px' } }}
          disabled>
          {saveButtonText}
        </LoadingButton>
      )}
    </Box>
  );
};

export const Notes = ({
  displayContent,
  displayContent1 = '',
}: {
  displayContent: string;
  displayContent1?: string;
}): JSX.Element => {
  return (
    <Typography
      component="div"
      sx={{
        backgroundColor: 'rgba(51, 127, 201, 0.04)',
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 20px',
        borderRadius: '5px',
        alignItems: 'flex-start',
        mt: 0.5,
        mb: 1,
        width: '100%',
        boxSizing: 'border-box',
      }}>
      <InfoOutlinedIcon color="info" fontSize="small" />
      <Typography
        sx={{
          color: 'rgba(41, 49, 57, 0.7)',
          fontSize: '13px',
          fontWeight: 500,
          ml: 1,
        }}>
        <Typography
          sx={{
            color: 'rgba(41, 49, 57, 0.7)',
            fontSize: '13px',
            fontWeight: 500,
            ml: 1,
          }}>
          {displayContent}
        </Typography>
        {displayContent1 && (
          <Typography
            sx={{
              color: 'rgba(41, 49, 57, 0.7)',
              fontSize: '13px',
              fontWeight: 500,
              ml: 1,
              mt: 0.5,
            }}>
            {displayContent1}
          </Typography>
        )}
      </Typography>
    </Typography>
  );
};

export const NotesWithText = ({
  displayContent,
  displayContent1 = '',
  noteTitle = 'Note',
  background = 'rgba(51, 127, 201, 0.04)',
  textColor = 'rgba(41, 49, 57, 0.7)',
  sx,
}: {
  displayContent: string;
  displayContent1?: string;
  noteTitle?: string;
  background?: string;
  textColor?: string;
  sx?: SxProps;
}): JSX.Element => {
  return (
    <Typography
      component="div"
      sx={{
        backgroundColor: background,
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 20px',
        borderRadius: '5px',
        alignItems: 'flex-start',
        mt: 0.5,
        mb: 1,
        width: '100%',
        boxSizing: 'border-box',
        ...sx,
      }}>
      <Typography
        sx={{
          color: textColor,
          fontSize: '13px',
          fontWeight: 500,
          ml: 0,
          display: 'flex',
        }}>
        <Typography
          component="span"
          sx={{
            color: textColor,
            fontSize: '13px',
            fontWeight: 600,
          }}>
          {noteTitle}:{'  '}
        </Typography>
        <Typography
          sx={{
            color: textColor,
            fontSize: '13px',
            fontWeight: 500,
            ml: 1,
          }}>
          {displayContent}
          {displayContent1 && (
            <Typography
              sx={{
                color: textColor,
                fontSize: '13px',
                fontWeight: 500,
                mt: 0.5,
              }}>
              {displayContent1}
            </Typography>
          )}
        </Typography>
      </Typography>
    </Typography>
  );
};

export const InfoDialog = ({
  onClose,
  open,
  content,
}: {
  onClose: any;
  open: any;
  content: string;
}): JSX.Element => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        '.MuiPaper-root ': {
          maxWidth: 400,
          p: { xs: 2, sm: '15px 20px 45px' },
          borderRadius: '10px',
          height: 'unset',
          overflowY: 'auto',
        },
      }}>
      <IconButton onClick={onClose} sx={{ alignSelf: 'flex-end' }}>
        <CloseIcon fontSize="medium" />
      </IconButton>
      <Box sx={{ mb: 2, textAlign: 'center', '.MuiSvgIcon-root': { fontSize: '3.1875rem' } }}>
        <InfoOutlinedIcon color="info" fontSize="large" />
      </Box>
      <Box
        sx={{
          width: { xs: '100%', sm: '80%' },
          mx: 'auto',
          '.MuiTypography-root': {
            fontSize: 20,
            fontWeight: 500,
            textAlign: 'center',
          },
        }}>
        <Typography sx={{ color: 'secondary.main', lineHeight: '35px' }}>{content}</Typography>
      </Box>
    </Dialog>
  );
};
